.mainConatiner {
  background: #ffffff;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  transition-timing-function: linear;
  transition: all 1s ease-out;
}

.borderStyle {
  border-top: 8px solid #243444;
}

.getStartedBackground {
  background-image: url("../assests/Strategy/hallmarks_bg_SS.svg");
}

.table_container {
  overflow-x: auto;
}
.tableUser1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  width: 100%;
}
.tableUser1 th,
.tableUser1 td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.tableUser1 th {
  font-size: 14px;
  padding: 10px 6px;
  text-transform: uppercase;
  color: #000;
  border-bottom: none;
  /* text-align: left; */
  font-family: "Montserrat", sans-serif;
}
.tableUser1 td {
  font-size: 14px;
  padding: 10px 6px;
  color: #000;
  border-bottom: none;
  /* text-align: right; */
  font-family: "Poppins", sans-serif;
}

.greenColor {
  background-color: rgba(24, 177, 63, 0.3);
  text-align: center;
}
.redColor {
  background-color: rgba(237, 71, 49, 0.3);
  text-align: center;
}
