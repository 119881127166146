.containermain {
  width: 80%;
  margin: 0px auto;
  padding-top: 100px;
}
.containermainInit {
  width: 80%;
  margin: 30px auto;
}
.Heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 10px auto;
  text-align: center;
  color: #000000;
}

.SubHeading {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  color: #000000;
}

.buttonContribute {
  height: fit-content;
  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  padding: 7px;
  text-align: center;
}

@media screen and (max-width: 700px) and (min-width: 100px) {
  .containermainInit {
    width: 100%;
    margin: 30px auto;
  }
  .buttonContribute {
    height: fit-content;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    padding: 5px;
    text-align: center;
  }
}
