@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  .underline-animation {
    @apply relative after:bg-white/60 after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-100;
  }
}

@layer components {
  .text-container {
    opacity: 0;
    animation: slideIn .4s ease forwards;
  }

  .text-container.show {
    opacity: 1;
    transform: translateY(0);
  }

  .hiddenAnimation {
    opacity: 0;
    transition-delay: 50ms;
    transform: translateY(20%);
    transition: linear .4s;
  }

  .showAnimation {
    opacity: 1;
    transform: translateY(0);
  }

  .borderStyle {
    border-top: 8px solid #243444;
    transition-timing-function: linear;
  }

  input:focus-visible {
    outline: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  /* Utility classes if needed */
}

/* Scoped Carousel Styles */
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 200px; /* Adjust this height according to your needs */
}

.carousel-content {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: max-content; /* Ensures the width dynamically adjusts to the content */
  height: 100%;
  animation: scroll 16s linear infinite; /* Adjust timing based on content length */
}

.carousel-item {
  flex-shrink: 0;
  width: auto; /* Adjust width based on the content */
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
