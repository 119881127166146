.card {
  width: 300px; /* Adjust card width */
  height: 200px; /* Adjust card height */
}

.overlay {
  z-index: 1;
}

.text {
  z-index: 2;
}


