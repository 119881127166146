.backgroundHero {
  position: relative;
}

.backgroundImage {
  position: relative;
  width: 100%;
  height: 98vh;
  background-image: url("../../assests/HeroBackground.jpg");
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1; /* Ensure the overlay is behind the text content */
}

.textContent {
  position: absolute;
  z-index: 2; /* Ensure the text content is on top of the overlay */
  color: white; /* Set text color */
}

@media (max-width: 600px) {
  .backgroundImage {
    width: 100%;
    height: 90vh;
  }
}
