.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  display: none;
  position: absolute;
  min-width: 280px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  font-size: 16px;
}

.dropdown_content a {
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown_content {
  display: block;
}

.dropbtn2 {
  min-width: 200px;
  text-align: left;
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

.dropdown_content2 {
  display: none;
  position: absolute;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 201px;
  top: 0;
  
}

.dropdown_content2 a {
  /* padding: 16px 20px; */
  text-decoration: none;
  display: block;
}

/* .dropdown2:hover .dropdown_content2 {
  display: block;
} */
